
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.main-container{
    font-family: 'Prompt' !important;
    max-width: 1000px;
    text-align: center;
    margin: auto;
    background-color: #ffffff;
    height: 100vh;
}

.banner_header{
    width: 100%;
}

.inputSearchText{
    display: flex;
    width: 500px;
    height: 52px;
    min-width: 343px;
    padding: 18px 16px;
    justify-content: space-between;
    align-items: center;

    border-radius: 100px;
    background: var(--light-grey, #ffffff);
    box-shadow: 0px 0px 4px 0px rgba(113, 128, 158, 0.25);
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-input:placeholder-shown{
    font-family: 'Prompt';
}

html{
    background-color: #f5f5f5;
}

.tooltip{
    font-size: 13px;
    color: #71809E;
}


.headerSection{
    background: var(--light-blue, #F5F7FD);
    box-shadow: 0px 2px 8px 0px #F0F1F2;
    line-height: 0px;
}

.mainContent{
    min-height: 85vh;
    background-image: url('../../public/image/bs21_background.png');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}



.header_text{

    font-family: 'Prompt' !important;
    color: var(--dark-blue-dark-blue-1, #045CA9);
    text-align: center;

    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px; /* 140% */
}


.searchSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Prompt' !important;

}

.headerContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.flagLanguage{
    cursor: pointer;
}

.disabledFlag{
    opacity: 0.5;
}

.tableRegister{
    margin-top: 50px;
    width: 90%;
    min-height: 70vh;
}

.eventName{
    color: var(--dark-grey, #71809E);
    font-size: 14px;
    font-weight: 500;
}

.ticketSection{
    font-family: 'Prompt';

    margin-top: 50px;
    width: 90%;
    min-height: 70vh;
    padding: 24px;

    border-radius: 20px;
    border: 1px solid var(--grey, #CCD2DE);
    background: var(--light-blue, #F5F7FD);
    box-shadow: 0px 0px 4px 0px rgba(113, 128, 158, 0.25);
}

.ticketHeader{
    color: var(--main-grey, #2A4058);
    font-size: 28px;
    font-weight: 500;
    line-height: 40px; /* 142.857% */
}


.qrcodeSection{
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    
    width: 400px;

    border-radius: 18px;
    background: var(--grey-blue, #D8E9FF);
}

.ticketDescription{
    color: var(--dark-grey, #71809E);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.registerStatus{
    color: #A7E521;
    font-size: 16px;
}

.ticketContent{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.ticketDetailSection{
    width: 900px;
}

.titleSubHeader{
    color: var(--dark-grey, #71809E);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-top: 20px;
}

.registerMainDetail{
    color: var(--main-grey, #2A4058);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
}

.logoSection{
    cursor: pointer;
}

.clientName{
    font-size: 16px;
}

.copyRight{
    color: var(--dark-grey, #71809E);
    font-size: 12px;
}

@media screen and (max-width: 800px) {

    .header_text{
        font-size: 24px;
    }
}


@media screen and (max-width: 700px) {

    .searchSection{
        width: 90%;
    }

    .header_text{
        font-size: 20px;
    }

    .inputSearchText{
        width: 95%;
    }

    .ticketContent{
        flex-direction: column;
    }

    .qrcodeSection{
        margin-top: 50px;
        width: 300px;
        width: 100%;
    }

    .txidLogo{
        width: 120px;
    }

    :where(.css-dev-only-do-not-override-1x0dypw).ant-input:placeholder-shown{
        font-family: 'Prompt';
        font-size: 10px !important;
    }

    .ticketDetailSection{
        width: 90%;
    }

    .ticketHeader{
        font-size: 20px;
    }

    .registerMainDetail{
        font-size: 18px;
    }

    .titleSubHeader{
        margin-top: 20px;
        font-size: 16px;
    }

    .headerSection{
        padding: 0 20px !important; 
    }

    .mainContent{
        min-height: 70vh;
    }
}




